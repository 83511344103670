@import '_include_media';

#isso-thread {
  input[name="website"] {
    display: none;
  }

  h4 {
    font-family: "Avenir", sans-serif;
  }

  .textarea {
    border: 1px solid #b36c38;
    box-shadow: none;
    padding: .8em .8em;
    margin-bottom: 1em;
  }

  .input-wrapper {
    max-width: 33%;
    border-bottom: 1px solid #b36c38;
    margin-right: 1em;
    input {
      box-shadow: none;
      border: none;
    }
  }
  input[value="Submit"] {
    background-color: #8B8535;
    color: white;
  }
  input[type="submit"],input[type="button"], {
    padding: calc(1em - 1px);
  }
  .text-wrapper, .isso-postbox {
    font-family: "Clear Sans", Georgia,"Times New Roman",Times,serif;
  }
  a { 
    color: #b36c38;
  }
  input[name="preview"] {
    display: none;
  }

  @media screen and (max-width: 1023px){
    div.textarea {
      min-height: 100px;
    }
    p input[type="text"], input[type="email"]{
      line-height: 2em;
      font-size: 2em;
    }
    input[type="submit"],input[type="button"], {
      padding: calc(0.5em - 1px);
      font-size: 2em;
    }
    #isso-root {
      font-size: 2.5rem;
      a svg {
        width: 48;
        height: 48;
      }
    }
  }
}

$small: 1024px;
$large: 1280px;

.header {
  width: 100%;
  display: block;
  .logo {
    width: 50%;
    position: relative;
    margin: 0 auto;
    display: block;
  }
}

.intro {
  width: 100%;
  #title {
    margin: 0 auto;
    padding: 0 0 0 20px;
    text-align: center;
    font-family: 'Avenir', 'Avenir Medium', sans-serif;
    font-weight: 500;
    color: #4D4845;
    font-size: 1rem;
    @media screen and (max-width: $small) {
      font-size: 2rem;
    }
  }
}
body {
  // background-color: rgb(239, 235, 227);
  background-color: white;
  // margin-bottom: 300px;
  margin-bottom: 100px;
}

.container > iframe {
  margin-top: 100px;
  // max-width: 300px;
  display: inline-block;
}

.chapter-num, .verse-num, .footnote, .footnotes {
  display: none;
}

.container {
  display: block;
  margin: 0 auto;
}

.scripture-container {
  font-size: 1rem;
  @media screen and (max-width: $small) {
    font-size: 3rem;
  }
}

#esv-scripture.scriptures.esv-text {
  font-family: "Clear Sans", Georgia,"Times New Roman",Times,serif;
}

.scripture-block > :not(.extra_text) {
  transition: height 0.2s linear;  
  height: 0;
  overflow: hidden;
}  

.scripture-block > :not(.extra_text) {
  display: block;
  margin: 0;
  padding: 0;
  overflow: hidden;  
}

.esv-text h2.extra_text {
  color: #4D4845;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
  font-size: 1.5rem;
  font-family: 'Avenir', sans-serif;
  font-style: normal;
  text-align: center;
  // border-bottom: 1px solid #b36c38;
  @media screen and (max-width: $small) {
    font-size: 3rem;
  }
  position: relative;
}

.esv-text h2.extra_text::after {
  content: " ⌄";
  position: absolute;
  bottom: 5px;
  // right: -2;
}

.esv-text .scripture-block.open h2.extra_text::after {
  content: " ⌃";
  position: absolute;
  top: 8px;
}

@media screen and (max-width: 767px){
    .container {
        width: 90%;
        padding: 0 10px;
    }
}
/* Tablets and iPads (portrait and landscape) ---------- */
// @media screen and (min-width: 768px) and (max-width: 1023px){
//     .container {
//         width: 60%;
//         padding: 0 10px;
//     }
// }
/* Low resolution desktops and laptops ---------- */
@media screen and (min-width: 1024px) and (max-width: 1280px) {
    .container {
        width: 50%;
        padding: 0 15px;
    }
    #single-song-player {
      transform: scale(0.5);
    }    
}
/* High resolution desktops and laptops ---------- */
@media screen and (min-width: 1280px) {
    .container {
        width: 40%;
        padding: 0 20px;
    }

    #single-song-player {
      transform: scale(0.5);
    }
}

.scriptures.esv-text, .esv-text .block-indent span.indent {
  display: block;
  margin: 0 auto;
}

/*
  2. Components
*/
div.control-container {
  margin-top: 10px;
  padding-bottom: 10px;
}
  div.control-container div.amplitude-play-pause {
    width: 148px;
    height: 148px;
    cursor: pointer;
    float: left;
    margin-left: 10px; }
  div.control-container div.amplitude-play-pause.amplitude-paused {
    background: url("https://521dimensions.com/img/open-source/amplitudejs/examples/single-song/play.svg");
    background-size: cover; }
  div.control-container div.amplitude-play-pause.amplitude-playing {
    background: url("https://521dimensions.com/img/open-source/amplitudejs/examples/single-song/pause.svg");
    background-size: cover; }
  div.control-container div.meta-container {
    float: left;
    width: calc(100% - 316px);
    text-align: center;
    color: white;
    margin-top: 10px; }
    div.control-container div.meta-container span[data-amplitude-song-info="name"] {
      font-family: "Open Sans", sans-serif;
      font-size: 36px;
      color: #fff;
      display: block; }
    div.control-container div.meta-container span[data-amplitude-song-info="artist"] {
      font-family: "Open Sans", sans-serif;
      font-weight: 100;
      font-size: 28px;
      color: #fff;
      display: block; }
  div.control-container:after {
    content: "";
    display: table;
    clear: both; }

/*
  Small only
*/
// @media screen and (max-width: 39.9375em) {
//   div.control-container div.amplitude-play-pause {
//     background-size: cover;
//     width: 64px;
//     height: 64px; }
//   div.control-container div.meta-container {
//     width: calc(100% - 168px); } }
/*
  Medium only
*/
/*
  Large Only
*/
div.time-container {
  opacity: 0.5;
  font-family: 'Open Sans';
  font-weight: 100;
  font-size: 20px;
  color: #fff;
  height: 15px; }
  div.time-container span.current-time {
    float: left;
    margin-left: 5px; }
  div.time-container span.duration {
    float: right;
    margin-right: 5px; }

/*
  Small only
*/
/*
  Medium only
*/
/*
  Large Only
*/
progress.amplitude-song-played-progress {
  background-color: blue;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 5px;
  display: block;
  cursor: pointer;
  border: none;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

progress.amplitude-song-played-progress:not([value]) {
  background-color: blue;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

progress[value]::-webkit-progress-bar {
  background-color: #A8AA2F;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

// progress[value]::-moz-progress-bar {
//   background-color: #A8AA2F;
//   border-top-right-radius: 10px;
//   border-radius-top-left: 10px;
// }

progress[value]::-webkit-progress-value {
  background-color: #E47A3C;
  border-top-left-radius: 10px;
  // border-top-left-radius: 10px;
}

/*
  Small only
*/
/*
  Medium only
*/
/*
  Large Only
*/
/*
  3. Layout
*/
div.bottom-container {
  background-color: #8B8535;
  border-radius: 10px;
  padding-top: 5px;
}

/*
  Small only
*/
/*
  Medium only
*/
/*
  Large Only
*/
div#single-song-player {
  z-index: 100;
  border-radius: 10px;
  margin: auto;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin-top: 50px;
  width: 90%;
  max-width: 800px;
  -webkit-font-smoothing: antialiased;
  position: fixed;
  bottom: 60px;
  left: 10px;
  right: 10px;
  @media screen and (min-width: $small) {
    width: 50%
  }
}

div#single-song-player img[data-amplitude-song-info="cover_art_url"] {
    width: 100%;
    border-radius: 10px;
}

div.song-image{
  width: 148px;
  margin-right: 10px;
  display: inline-block;
}

.woc {
  color: #A02323;
}

a.learn-more{
  display: block;
  width: 300px;
  margin: auto;
  margin-top: 30px;
  text-align: center;
  color: white;
  text-decoration: none;
  background-color: #202136;
  font-family: "Lato", sans-serif;
  padding: 20px;
  font-weight: 100;
}